<template>
    <div>
        <ProductsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">

            <h1>Curb Adapters</h1>

            <img :src="`${$cdiVars.BASE_URL}/images/v3/cdi_products_adapters.jpg`" class="img-responsive img_content_right">

            <ul class="list_content">
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_CA_040724.pdf`" target='_install'>Curb Adapter</a></li>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_CCA_040724.pdf`" target='_install'>Concentric Curb Adapter</a></li>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_STRAD_072424.pdf`" target='_install'>Stradapter</a></li>
            </ul>

            <h1>Custom Curbs</h1>

            <h4>We pride ourselves in being able to create solutions for your most challenging projects</h4>

            <h3>Vibration Isolation</h3>
            <ul class='list_content'>
                <li>Isolation Curb</li>
                <li>Isolation Rail</li>
                <li>Seismic Load Versions (Conforming Curbs)</li>
                <li>Wind Load Versions</li>
            </ul>

            <h3>Straight Curbs</h3>
            <ul class='list_content'>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_FAS_040724.pdf`" target='_install'>Fully Assembled Curb</a></li>
                <li>Knock Down Curb</li>
                <li>Welded Curb</li>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_HC_040724.pdf`" target='_install'>Horizontal Discharge Curb</a></li>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_EFC_040724.pdf`" target='_install'>Exhaust Fan Curb</a></li>
                <li>Seismic Versions (Conforming Curbs)</li>
                <li>Wind Load Versions</li>
            </ul>

            <h3>Applied Curbs</h3>
            <ul class='list_content'>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_PC_040724.pdf`" target='_install'>Pitched Curb</a></li>
            </ul>

            <h3>Pitched Curbs</h3>
            <ul class='list_content'>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_EFC_040724.pdf`" target='_install'>Exhaust Fan Curb</a></li>
                <li>Welded Curb</li>
                <li>Metal Building Curb</li>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_EFC_040724.pdf`" target='_install'>Exhaust Fan Curb</a></li>
                <li>Seismic Load Versions (Conforming Curbs)</li>
                <li>Wind Load Versions</li>
            </ul>

            <h3>Custom / Misc Fabrication</h3>
            <ul class='list_content'>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_ER_040724.pdf`" target='_install'>Equipment Rail</a></li>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_ss_BB_040724.pdf`" target='_install'>Burglar Bars</a></li>
                <li>S Clips / Hold Downs, Tie Downs w/Turnbuckle</li>
            </ul>
        </main>
    </div>
</template>

<script>
import ProductsNav from '@/components/content/ProductsNav.vue'
export default {
    name: "CustomCurbs",
    components: {
        ProductsNav,
    },
    created() {
        this.$appStore.setTitle(['Custom Curb Adapters']);
    },
}
</script>

<style scoped>

</style>